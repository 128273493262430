const TAB = `TAB`;


const initialState = {
    tab: 0,
};

const finance = (state = initialState, action) => {
    switch (action.type) {
        case TAB:
            return {...state, tab: action.payload};
        default:
            return state;
    }
};

export default finance

export const toggleTabs = (m) => (dispatch) => {
    dispatch({type: TAB, payload: m});
};

