import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";

import { queryClient } from "./services/api";
import store from "./redux/index";
import {ReactQueryDevtools} from "react-query/devtools";

ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        {/* <ReactQueryDevtools/> */}
      </QueryClientProvider>
    </Provider>
  ,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
