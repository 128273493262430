import { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Spin } from 'antd';

import "react-image-gallery/styles/scss/image-gallery.scss";
import 'antd/dist/antd.less';
import './assets/scss/app.scss';
import 'bootstrap/dist/css/bootstrap.css'

import getCurrentUser from './utils/getCurrentUser';
// Test CI/Cd
const App = () => {
  const user = getCurrentUser();

  let role = null;
  const roles = (user || {}).role || [];
  if (roles.find((item = {}) => item.roleName === "SUPERADMIN")) {
    role = "super-admin";
  } else if (roles.find((item = {}) => item.roleName === "ADMIN")) {
    role = "admin";
  } else if (roles.find((item = {}) => item.roleName === "MODERATOR")) {
    role = "moderator";
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Spin size="large" spinning={true}><div className="full-height flex-center"></div></Spin>}>
          <Switch>
            <Route path="/" exact>
              <Redirect to={user && role ? `/${role}` : "/login"} />
            </Route>
            <Route path="/login" exact component={lazy(() => import("./pages/Login"))} />
            {user && (
              <>
                {role === "moderator" ? (
                  <Route path="/moderator" component={lazy(() => import("./pages/Moderator"))} />
                ) : (<Route path="/moderator" component={lazy(() => import("./pages/NotFound"))} />)}
                {role === "admin" ? (
                  <Route path="/admin" component={lazy(() => import("./pages/Admin"))} />
                ) : (<Route path="/admin" component={lazy(() => import("./pages/NotFound"))} />)}
                {role === "super-admin" ? (
                  <Route path="/super-admin" component={lazy(() => import("./pages/SuperAdmin"))} />
                ) : (<Route path="/super-admin" component={lazy(() => import("./pages/NotFound"))} />)}
              </>
            )}
            <Route component={lazy(() => import("./pages/NotFound"))} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
