import axios from "axios";

import { QueryClient } from "react-query";

import { getCookie, removeCookie } from "src/utils/cookie";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false
		}
	}
});

export const request = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		common: {
			Authorization: "Bearer " + getCookie("token")
		}
	}
});
request.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401 || error.response.status === 403) {
			removeCookie("token");
			removeCookie("user");
			window.location.reload();
			window.location.pathname ="/";
		}
		throw error;
	}
);
request.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    common: {
      Authorization: "Bearer " + getCookie('token')
    }
  }
  return config;
}, error => Promise.reject(error));

export const requestNotAuth = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});
